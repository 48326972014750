(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name wbOrder.controller:MultiWalletDataReconciliationListCtrl
   *
   * @description
   *
   */
  angular
    .module('wbOrder')
    .controller('MultiWalletDataReconciliationListCtrl', MultiWalletDataReconciliationListCtrl);

  function MultiWalletDataReconciliationListCtrl(LoggedUser, List, FormControl, _, DataReconciliation, $mdToast, $state, cardTypeMap, Cart) {
    var vm = this
      , formControl = new FormControl()
      , company = LoggedUser.getCompany()
      , EdenredECSselectedProductType = 'EdenredEcsSelectedProductType'
      , multiWallet = 'multiWallet'
      , dataReconciliationList = new List(company.getDataReconciliation());

    vm.processStatusList = [
      {
        type: 0,
        title: 'order.multiWalletDataReconciliationList.process.0.title'
      },
      {
        type: 1,
        title: 'order.multiWalletDataReconciliationList.process.1.title'
      },
      {
        type: 2,
        title: 'order.multiWalletDataReconciliationList.process.2.title'
      },
      {
        type: 3,
        title: 'order.multiWalletDataReconciliationList.process.3.title'
      },
      {
        type: 4,
        title: 'order.multiWalletDataReconciliationList.process.4.title'
      },
      {
        type: 5,
        title: 'order.multiWalletDataReconciliationList.process.5.title'
      },
      {
        type: 6,
        title: 'order.multiWalletDataReconciliationList.process.6.title'
      }
    ];
    vm.allSelected = false;
    vm.dataReconciliationFilterForm = {};
    vm.filter = {
      staffName: '',
      status: '',
      dateFrom: null,
      dateTo: null
    };

    function updateOrderTypeForSessionUser(orderType) {
      var currentProductTypeId = cardTypeMap[multiWallet]
        , newCardOrder = '1'
        , topUpOrder = '2'
        , cartId = orderType === 'new-card' ? newCardOrder : topUpOrder;

      return Cart
        .updateOrderTypeForSessionUser({
          cartId: cartId,
          category: currentProductTypeId
        })
        .$promise;
    }

    vm.goToState = function goToState(orderType) {
      var newCardState = 'multiWalletCardItemList'
        , topUpState = 'itemList'
        , stateToGo = orderType === 'new-card' ? newCardState : topUpState;

      localStorage.setItem(EdenredECSselectedProductType, multiWallet);
      updateOrderTypeForSessionUser(orderType)
        .then($state.go(stateToGo, {type: orderType}));
    };

    vm.filterSubmit = function () {
      dataReconciliationList.setFilter(vm.filter);
      dataReconciliationList.refreshList();
    };

    vm.filterSubmit();

    vm.isProcessing = function isProcessing() {
      return dataReconciliationList.isLoading();
    };

    vm.getDataReconciliationList = function getDataReconciliationList() {
      return dataReconciliationList.getList();
    };

    vm.isPreviousPageAvailable = function isPreviousPageAvailable() {
      return dataReconciliationList.hasPrevoiusPage();
    };

    vm.isNextPageAvailable = function isNextPageAvailable() {
      return dataReconciliationList.hasNextPage();
    };

    vm.getPage = function getPage() {
      return dataReconciliationList.getPage();
    };

    vm.nextPage = function nextPage() {
      dataReconciliationList.nextPage();
      dataReconciliationList.refreshList();
    };

    vm.previousPage = function previousPage() {
      dataReconciliationList.previousPage();
      dataReconciliationList.refreshList();
    };

    vm.isProcessing = function isProcessing() {
      return formControl.isProcessing();
    };

    vm.getServerValidation = function getServerValidation() {
      return formControl.getServerValidation();
    };

    /**
     * Delete selected data reconciliation items
     */
    vm.deleteDataReconciliation = function deleteDataReconciliation(itemId) {
      var ids = [];

      if (itemId) {
        ids.push(itemId);
      } else {
        _.forEach(vm.getSelectedDataReconciliations(), function (dataReconciliation) {
          ids.push(dataReconciliation.id);
        });
      }

      DataReconciliation.delete(ids).$promise
        .then(function () {
          $mdToast.showToast('order.multiWalletDataReconciliationList.toast.delete');
          dataReconciliationList.refreshList();
          vm.clearActiveSelection();
        });
    };

    /**
     * Request email notification for selected data reconciliation items
     */
    vm.requestEmailNotification = function requestEmailNotification(itemId) {
      var ids = [];

      if (itemId) {
        ids.push(itemId);
      } else {
        _.forEach(vm.getSelectedDataReconciliations(), function (dataReconciliation) {
          ids.push(dataReconciliation.id);
        });
      }

      DataReconciliation.requestEmailNotification(ids).$promise
        .then(function () {
          $mdToast.showToast('order.multiWalletDataReconciliationList.toast.email');
          dataReconciliationList.refreshList();
          vm.clearActiveSelection();
        });
    };

    /**
     * Toggles selection for all data reconciliation items
     */
    vm.selectAll = function selectAll() {
      vm.allSelected = !vm.allSelected;

      _.forEach(vm.getDataReconciliationList(), function (dataReconciliation) {
        dataReconciliation.isSelected = vm.allSelected;
      });
    };

    /**
     * Clears active selections for data reconciliaton items
     */
    vm.clearActiveSelection = function clearActiveSelection() {
      vm.allSelected = false;

      _.forEach(vm.getDataReconciliationList(), function (dataReconciliation) {
        dataReconciliation.isSelected = false;
      });
    };

    /**
     * Returns an array of data reconciliation items selected by the user
     *
     * @returns {Array} Array of data reconciliation items selected by the user
     */
    vm.getSelectedDataReconciliations = function getSelectedDataReconciliations() {
      return _.filter(vm.getDataReconciliationList(), 'isSelected');
    };

    /**
     * Returns whether there are selected items among the data reconciliation items
     *
     * @returns {boolean} Whether there are selected items among the data reconciliation items
     */
    vm.hasSelectedDataReconciliations = function hasSelectedDataReconciliations() {
      return _.some(vm.getDataReconciliationList(), 'isSelected');
    };
  }
}());
